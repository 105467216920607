<template>
  <wbgl-yaheen />
</template>
<script lang="ts">
import yaheen from "@/components/webgl/yaheen.vue";
import { defineComponent, getCurrentInstance } from "vue";
export default defineComponent({
  name: "WebglTest",
  setup() {
    const { proxy }: any = getCurrentInstance();
    return {
      proxy: proxy,
    };
  },
  created() {},
  methods: {},
  components: {
    "wbgl-yaheen": yaheen,
  },
});
</script>
<style lang="scss" scoped></style>
